import React from 'react'
import { IntlContextConsumer, changeLocale } from 'gatsby-plugin-react-intl'

const languageName = {
  en: '🇬🇧',
  de: '🇩🇪'
}

const Language = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <a
              key={language}
              onClick={() => changeLocale(language)}
              style={{
                color: 'white',
                margin: 5,
                float: 'right',
                textDecoration: currentLocale === language ? 'underline' : 'none',
                cursor: 'pointer',
                fontSize: '24px'
              }}
            >
              {languageName[language]}
            </a>
          ))}
      </IntlContextConsumer>
    </div>
  )
}

export default Language
