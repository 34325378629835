import React from 'react'
import styled from 'styled-components'
import smartphone from '../img/smartphone_empty.svg'
import { StaticImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

const Slider = (props) => {
  const items = [

    <StaticImage
      key={1}
      src='../img/products01.png'
      alt='Smartphone'
      placeholder='blurred'
      layout='fixed'
      width={200}
      style={{ margin: '10px auto 0px' }}
    />,
    <StaticImage
      key={2}
      src='../img/products02.png'
      alt='Smartphone'
      placeholder='blurred'
      layout='fixed'
      width={200}
      style={{ margin: '10px auto 0px' }}
    />
  ]
  // const [colorized, setColorized] = useState(1)

  // useEffect(() => {
  //   handleChange()
  //   // renderItems()
  // }, [props])

  // function handleChange(isSelected) {
  //   // do whatever with index
  //   // alert(isSelected)
  //   isSelected ? setColorized(0.2) : setColorized(0.2)
  //
  // }

  // let inputStyle = {
  //     opacity: 1
  //   };

  return (
    <Section>
      <StyledCarousel showArrows={true} showStatus={false} showThumbs={false} showIndicators={true}>
      {/*<img src={i1} className='slide' style={{ transform: `translate(${x}%)`, opacity: (x === 150 ? '1' : '0.2') }} />
      <img src={i2} className='slide' style={{ transform: `translate(${x}%)` }} />
      <img src={i3} className='slide' style={{ transform: `translate(${x}%)`, opacity: (x === 450 ? '1' : '0.2') }} />*/}


        {
          items.map((item, index) => {
            return (
              <>
                <div key={index}>
                  {item}
                </div>

              </>
            )
          })
        }

      </StyledCarousel>
      <img src={smartphone} style={{ position: 'absolute', top: '0', left: '0', right: '0', width: 'auto', margin: '0 auto', height: '450px', zIndex: '1000' }} />
    </Section>
  )
}

export default Slider

const Section = styled.section`
  // background-image: url{smartphone};
  position: relative;
  // min-height: 100vh;
`

const StyledCarousel = styled(Carousel)`
  .carousel.carousel-slider{
    margin: 0 auto !important;
  }
  .carousel .slide {
    background: transparent;
  }
  // li.slide-selected{
  //   width: 200px;
  // }
`
