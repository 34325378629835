import React from 'react'
// import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import ExpressContactForm from './ExpressContactForm'
import { FormattedMessage } from 'gatsby-plugin-react-intl'
// import Loadable from 'react-loadable'
import Fade from 'react-reveal/Fade'
import LazyLoad from 'react-lazyload'

// const Fade = Loadable({
//   loader: () => import('react-reveal/Fade'),
//   loading: () => <div>Loading...</div>
// })

const IOSWrap = styled.div`
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
`

const GetStarted = ({ className }) => (
  <StaticQuery
    query={graphql`
    query {
      desktop: file(relativePath: { eq: "get-started.jpg" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}
    render={data => {
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <>
          <div className='imageContainer' id='starting' style={{ position: 'relative', height: '50vh' }}>
            <Img
              // fluid={data.image.childImageSharp.fluid}
              fluid={imageData}
              style={{
                width: '100%',
                height: '50vh',
                position: 'absolute',
                left: '0px',
                right: '0px',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                filter: 'brightness(40%)',
                margin: '0rem auto'
              }}
            />

            <LazyLoad>
              <Fade>
                <IOSWrap>
                  <div className='section'>
                    <div className='columns is-vcentered is-centered is-relative is-multiline' style={{ paddingTop: '4%' }}>
                      <div className='column is-12 has-text-centered' style={{ position: 'relative' }}>
                        <h1 className='title is-2 is-size-3-mobile is-size-2-widescreen has-text-centered has-text-white' style={{ textShadow: '2px 2px 4px #000000' }}>
                          <FormattedMessage id='getStartedHeading' />
                        </h1>
                      </div>

                      <ExpressContactForm />

                      <div className='column is-10' style={{ position: 'relative' }}>
                        <h2 className='subtitle has-text-centered has-text-white' style={{ textShadow: '2px 2px 4px #000000' }}>
                          <FormattedMessage id='getStartedSubheading' />
                        </h2>
                      </div>
                    </div>
                  </div>
                </IOSWrap>
              </Fade>
            </LazyLoad>
          </div>
        </>
      )
    }}
  />
)

// const StyledBackgroundSection = styled(GetStarted)`
//   width: 100%;
//   background-position: bottom center;
//   background-repeat: repeat-y;
//   background-size: cover;
// `

export default GetStarted
