import React, { useState } from 'react'
import styled from 'styled-components'
import smartphone from '../img/smartphone_empty.svg'
// import Content from './CarouselContent'
import { StaticImage } from 'gatsby-plugin-image'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
// import Video from '../img/'
// import i1 from '../img/img01.jpeg'
// import i2 from '../img/img02.jpeg'
// import i3 from '../img/img02.png'

const Slider = (props) => {
  // const items = [
  //   <Content src={i1} />,
  //   <Content src={i2} />,
  //   <Content src={i3} />
  // ]

  const items = [
    <StaticImage
      // className='fl-2 benefits__image'
      key={1}
      src="../img/click-collect01.png"
      alt='smartphone'
      placeholder="blurred"
      layout="fixed"
      width={200}
      style={{ margin: '10px auto 0px' }}
      // style={{ margin: '0 auto', display: 'block', width: '250px' }}
    />,
    <StaticImage
      // className='fl-2 benefits__image'
      key={2}
      src="../img/click-collect02.png"
      alt='smartphone'
      placeholder="blurred"
      layout="fixed"
      width={200}
      style={{ margin: '10px auto 0px' }}
      // style={{ margin: '0 auto', display: 'block', width: '250px' }}
    />

    // <StaticImage
    //   key={3}
    //   // className='fl-2 benefits__image'
    //   src="../img/invoice03.png"
    //   alt='smartphone'
    //   placeholder="blurred"
    //   layout="fixed"
    //   width={200}
    //   style={{ margin: '10px auto 0px' }}
    //   // style={{ margin: '0 auto', display: 'block', width: '250px' }}
    // />
  ]
  const [colorized, setColorized] = useState(1)


  // useEffect(() => {
  //   handleChange()
  //   // renderItems()
  // }, [props])


  // function handleChange(isSelected) {
  //   // do whatever with index
  //   // alert(isSelected)
  //   isSelected ? setColorized(0.2) : setColorized(0.2)
  //
  // }


  // let inputStyle = {
  //     opacity: 1
  //   };

  return (
    <Section>
      <StyledCarousel emulateTouch showArrows={true} showStatus={false} showThumbs={false} showIndicators={true}>
      {/*<img src={i1} className='slide' style={{ transform: `translate(${x}%)`, opacity: (x === 150 ? '1' : '0.2') }} />
      <img src={i2} className='slide' style={{ transform: `translate(${x}%)` }} />
      <img src={i3} className='slide' style={{ transform: `translate(${x}%)`, opacity: (x === 450 ? '1' : '0.2') }} />*/}


        {
          items.map((item, index) => {
            return (
              <>


                <div key={index}>
                    {item}
                </div>

              </>
            )
          })
        }


      </StyledCarousel>
      <img src={smartphone} style={{ position: 'absolute', top: '0', left: '0', right: '0', width: '300px', margin: '0 auto', height: '450px', zIndex: '1000' }} />
    </Section>
  )
}

export default Slider

const Section = styled.section`
  // background-image: url{smartphone};
  position: relative;
  // min-height: 100vh;
`

const StyledCarousel = styled(Carousel)`
  .carousel.carousel-slider{
    margin: 0 auto !important;
  }
  .slider.animated{
    width: auto;
  }
  .carousel .slide {
    background: transparent;
  }
  li.slide-selected{
    div{
      max-width: 200px !important;
      // width: 300px;
      // width: 200px;
      // margin: 0px auto;
    }
  }
`
