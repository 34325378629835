import React, { useState, useRef } from 'react'
// import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
// import Img from 'gatsby-image'
import { StaticImage } from 'gatsby-plugin-image'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LazyLoad from 'react-lazyload'
import Fade from 'react-reveal/Fade'
// import BackgroundImage from 'gatsby-background-image'
import { FormattedMessage, injectIntl, Link } from 'gatsby-plugin-react-intl'
// import Loadable from 'react-loadable'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Carousel from './Carousel'
import Carousel01 from './Carousel01'
import Carousel02 from './Carousel02'
import CarouselWebapp from './CarouselWebapp'
import Carousel03 from './Carousel03'

// import ReservationStepper from './ReservationStepper'
// import InvoiceVideo from '../img/create-invoice.mp4'
// const Fade = Loadable({
//   loader: () => import('react-reveal/Fade'),
//   loading: () => <div>Loading...</div>
// })

// const LazyLoad = Loadable({
//   loader: () => import('react-lazyload'),
//   loading: () => <div>Loading...</div>
// })

const Benefits = ({ intl, data }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const playerRef = useRef(null)

  const handlePlayVideo = () => {
    playerRef.current.play()
    setIsPlaying(true)
    setIsPaused(false)
  }
  const handlePauseVideo = () => {
    playerRef.current.pause()
    setIsPaused(true)
    setIsPlaying(false)
  }
  // const image = getImage(data.showcase01)
  return (
    <IOSWrap>
      <section className='benefits' id='benefits' style={{ position: 'relative', background: '#2A2A2A', paddingBottom: '8rem' }}>

        <div className='hero'>
          <div className='hero-body'>
            <div className='columns is-vcentered is-centered' style={{ marginTop: '0px' }}>
              <div className='column is-half'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title is-spaced has-text-primary'>
                      <FormattedMessage id='benefit01Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit01Text' />

                    </BenefitText>


                  </Fade>
                </Benefit>
              </div>
              <LazyLoad height={200} once>
                <Carousel />
              </LazyLoad>
            </div>
          </div>
        </div>


        <LazyLoad once>
          <div className='hero'>
            <div className='hero-body'>

              <div className='columns is-multiline is-centered'>
                <div className='column is-6'>
                  <Benefit className='benefits'>
                    <Fade>
                      <BenefitHeading className='title has-text-primary'>
                        <FormattedMessage id='benefit02Heading' />
                      </BenefitHeading>
                      <BenefitText className='subtitle'>
                        <FormattedMessage id='benefit02Text' />
                      </BenefitText>
                      <div style={{ margin: '2rem auto' }}>
                        <Link to='/products' className='button is-primary is-inverted is-outlined'>
                          <FormattedMessage id='benefit01BtnTxt' />
                        </Link>
                      </div>
                    </Fade>
                  </Benefit>
                </div>

                {/*<div className='column is-12'>
                  <div style={{ maxWidth: '500px', margin: '0 auto' }}>
                    <StaticImage
                      key={1}
                      src='../img/showcase04.jpg'
                      alt='smartphone'
                      placeholder='blurred'
                      layout='constrained'
                      // width={320}
                      style={{ margin: '10px auto 10px' }}
                    />
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </LazyLoad>

        <div className='hero'>
          <div className='hero-body'>
            <div className='columns is-vcentered is-centered'>
              <div className='column is-6'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title has-text-primary'>
                      <FormattedMessage id='benefit03Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit03Text' />
                    </BenefitText>
                  </Fade>
                </Benefit>
              </div>
              <LazyLoad height={200} once>
                <Carousel02 />
              </LazyLoad>
            </div>
          </div>
        </div>


        <ImageWrapper>
          <StaticImage
            src='../img/click-collect-screen.png'
            alt='click-collect-screen'
            placeholder='blurred'
            layout='constrained'
            // width={800}

            // style={{ margin: '30px 10%' }}
            // aspectRatio={2 / 1}
            // width={320}
          />
        </ImageWrapper>


        <div className='hero'>
          <div className='hero-body'>
            <OrderedMobile className='columns is-vcentered'>
              <LazyLoad height={200} once>

                <Carousel03 />
              </LazyLoad>
              <div className='column is-half'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title has-text-primary'>
                      <FormattedMessage id='benefit04Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit04Text' />
                    </BenefitText>
                  </Fade>
                </Benefit>
              </div>
            </OrderedMobile>
          </div>
        </div>

        <div className='hero'>
          <div className='hero-body'>
            <div className='columns is-vcentered is-multiline is-centered'>
              <div className='column is-6'>
                <Fade>
                  <BenefitHeading className='title has-text-primary'>
                    <FormattedMessage id='benefit05Heading' />

                  </BenefitHeading>
                  <BenefitText className='subtitle'>
                    <FormattedMessage id='benefit05Text' />
                  </BenefitText>
                </Fade>
              </div>
              <Fade>
                <div className='column is-4'>
                  <StaticImage
                    src='../img/performance01.jpg'
                    alt='email'
                    placeholder='blurred'
                    layout='fixed'
                    width={280}
                    style={{ margin: '10px auto 0px' }}
                  />
                </div>
              </Fade>
              <Fade>
                <div className='column is-6'>
                  <StaticImage
                    src='../img/performance.png'
                    alt='email'
                    placeholder='blurred'
                    layout='constrained'
                    // width={300}
                    style={{ margin: '10px auto 0px' }}
                  />
                </div>
              </Fade>
            </div>
          </div>
        </div>

        <div className='hero'>
          <div className='hero-body'>
            <div className='columns is-vcentered is-centered'>
              <div className='column is-half'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title has-text-primary'>
                      <FormattedMessage id='benefit06Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit06Text' />
                    </BenefitText>
                  </Fade>
                </Benefit>
              </div>
            </div>
          </div>
        </div>

        <div className='hero'>
          <div className='hero-body'>
            <div className='columns is-vcentered is-centered'>
              <div className='column is-half'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title is-spaced has-text-primary'>
                      <FormattedMessage id='benefit07Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit07Text' />
                    </BenefitText>
                  </Fade>
                </Benefit>
              </div>
              <LazyLoad height={200} once>

                <CarouselWebapp />
              </LazyLoad>
            </div>
          </div>
        </div>


        <div className='hero'>
          <div className='hero-body'>
            <OrderedMobile className='columns is-centered is-vcentered' style={{ marginTop: '3rem' }}>
              {/*<StyledImage fixed={data.showcase02.childImageSharp.fixed} className='fl-2 benefits__image' alt='showcase02' />*/}

              {/*
                <StaticImage
                // className='fl-2 benefits__image'
                src="../img/smartphone.png"
                alt='smartphone'
                placeholder="blurred"
                layout="fixed"
                width={250}
                // style={{ margin: '0 auto', display: 'block', width: '250px' }}
                style={{ margin: '0 auto' }}
              />
              */}

              <LazyLoad height={200} once>
                <Carousel01 />
              </LazyLoad>

              <div className='column is-6'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title has-text-primary'>
                      <FormattedMessage id='benefit08Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit08Text' />
                    </BenefitText>
                  </Fade>
                </Benefit>
              </div>

            </OrderedMobile>
          </div>
        </div>

        {/*<div className='hero'>
          <div className='hero-body'>
            <div className='columns is-centered is-vcentered is-multiline'>
              <div className='column is-6'>
                <Benefit className='benefits'>
                  <Fade>
                    <BenefitHeading className='title has-text-primary'>
                      <FormattedMessage id='benefit09Heading' />
                    </BenefitHeading>
                    <BenefitText className='subtitle'>
                      <FormattedMessage id='benefit09Text' />
                    </BenefitText>
                  </Fade>
                </Benefit>
              </div>

              <Panel className='column is-6 has-text-centered'>
                <ReservationStepper />
              </Panel>
            </div>
          </div>
        </div>*/}

        {/* <div className='column is-12 has-text-centered' style={{ paddingTop: '4rem' }}>
          <Link to='/#getstarted'>
            <span className='button is-medium is-outlined is-primary' role='button'><FormattedMessage id='getStartedScrollButton' />&nbsp;<FontAwesomeIcon icon='gift' /></span>
          </Link>
        </div> */}
      </section>
    </IOSWrap>
  )
}

export default injectIntl(Benefits)

// export const pageQuery = graphql`
//   query {
//     showcase01: file(relativePath: { eq: "smartphone.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           width: 200
//           placeholder: BLURRED
//           formats: [AUTO, WEBP]
//         )
//       }
//     }
//   }
// `

const VideoWrapper = styled.video`
  min-Width: 320px;
  max-width: auto;
  // padding: 5% 0;
  min-height: 240px;
  max-height: auto;
  @media (max-width: 769px) {
    padding: 0% 5%;
  }
  @media (min-width: 769px) {
    padding: 0% 15% 5%;
  }
`

const IOSWrap = styled.div`
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
`

const Benefit = styled.div`
  @media (max-width: 769px) {
    margin: 2rem auto;
  }
  @media (min-width: 769px) {
    margin: 0rem auto;
  }
`

const BenefitHeading = styled.h1`
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  margin-bottom: 1rem;
`

const BenefitText = styled.p`
  hyphens: auto;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  text-align: justify;
`

const StyledImage = styled(StaticImage)`
  margin: 0 auto;
  @media (max-width: 769px) {
    max-width: 300px;
    width: 300px;
  };
  @media (min-width: 769px) {
    min-width: 300px;
  }
`

const OrderedMobile = styled.div`
  @media (max-width: 769px) {
    flex-direction: column-reverse;
    display: flex;
  }
`

const PlayerControlBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  margin: 2rem;
`

const ImageWrapper = styled.div`
  margin: 3.5rem auto 3.5rem;
  max-width: 800px;
`



const Panel = styled.div`
  border-radius: 3px;
  // background-color: rgba(0,0,0,.8);
  // box-shadow: 0 0 15px rgba(0,0,0,.6);
  // padding: 32px;
  margin-top: 1rem;
  margin-bottom: 2rem;
  position: relative;
`
