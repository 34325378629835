import React, { useState } from 'react'
import styled from 'styled-components'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl'
// import verwaldoLogo from '../img/logo.png'
// import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'

import axios from 'axios'

// const getBase64 = (file) => {
// return new Promise((resolve,reject) => {
//    const reader = new FileReader();
//    reader.onload = () => resolve(reader.result);
//    reader.onerror = error => reject(error);
//    // reader.readAsDataURL(file);
// });
// }

const ExpressContactForm = () => {
  const [formValues, setFormValues] = useState({ email: '' })
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [sending, setSending] = useState(false)

  var currentLocation = ''

  if (typeof window !== 'undefined') {
    currentLocation = window.location.href
  }
  // console.log(currentLocation)

  // localStorage.setItem('logo', verwaldoLogo)
  // useEffect(() => {
  //   const file = verwaldoLogo
  //   getBase64(file).then(base64 => {
  //     // localStorage["fileBase64"] = base64
  //     localStorage.setItem('logo', verwaldoLogo)
  //     console.debug("file stored",base64);
  //   })
  // })


  // const emailCustomer = createRef()
  // const companynamo = createRef()
  // const emailMessago = createRef()

  function handleInputChange (e) {
    // this.setState({ [e.target.name]: e.target.value })

    e.persist()
    // setErrorMessage('')
    setFormValues(currentValues => ({
      ...currentValues,
      [e.target.name]: e.target.value
    }))
  }

  function handleSubmit (e) {
    e.preventDefault()

    const customerEmailAddress = formValues.email
    // const userCompanyname = companynamo.current.value
    // const emailMessage = emailMessago.current.value

    // const { email } = props

    const to = 'office@digital-comfort-zone.com'

    const email = customerEmailAddress
    // const name = userCompanyname
    // const message = emailMessage

    // const logo = localStorage.getItem('logo')
    // const logo = verwaldoLogo

    // const { invoices } = this.state

    // const companyname = this.companyname.current.value

    // const date = this.state.date

    // const time = this.state.time

    // const title = this.title.current.value
    // const firstname = this.firstname.current.value
    // const secondname = this.secondname.current.value
    // const email = this.email.current.value
    setSending(true)
    // const { name, message, to } = this.state
    // const { message } = this.state
    // const { mailheader } = this.state

    // if(currentLocation === 'https://www.verwaldo.com/en/' || 'https://www.verwaldo.com') {
    axios.post('/.netlify/functions/contact-form', {
      // name,
      // customercompanyname,
      email,
      // message,
      to
      // logo
    }).then((response) => {
      if (response.status === 200) {
        setSending(false)
        setSuccess(true)
      }
      // navigate('/contact/thanks')
      // console.log('success', response)
      return {
        statusCode: 200,
        body: JSON.stringify(response)
      }
    }).catch((error) => {
      // console.log('error', error)
      setSending(false)
      setError(true)
      return {
        statusCode: 400,
        body: JSON.stringify(error)
      }
    //  console.log('...dont know')
    })
  }

  function renderSuccess () {
    setTimeout(() =>
      setSuccess(false), 15000)
    if (success === true) {
      return (
        <Success><FormattedMessage id='successSendMessage' />{' '}<FontAwesomeIcon icon={faCheck} />
        </Success>
      )
    }
  }

  function renderSendingTime () {
    if (sending === true) {
      return (
        <Info><FormattedMessage id='sendingMessage' />
        </Info>
      )
    }
  }

  function renderError () {
    setTimeout(() =>
      setError(false), 15000)
    if (error === true) {
      return (
        <Error><FormattedMessage id='errorSendMessage' />{' '}<FontAwesomeIcon icon={faTimes} />
        </Error>
      )
    }
  }

  return (
    <div>
      <form
        id='contact-form' onSubmit={handleSubmit} method='POST'
        onSubmit={handleSubmit}
      >
        <div class='field has-addons has-addons-centered' style={{ margin: '2rem' }}>
          <div class='control is-expended' style={{ boxShadow: '0 8px 12px 0 rgba(0,0,0,0.8)', minWidth: '220px', maxWidth: '300px' }}>
            <label className='is-sr-only' for='email'>Email</label>
            <Input
              className="input is-medium is-primary" style={{ borderRadius: '0px' }}
              type={'email'}
              name={'email'}
              onChange={handleInputChange}
              id={'email'}
              // ref={emailCustomer}
              value={formValues.email}
              required={true}
              placeholder={'email@address.com'}
            />
          </div>
          <div class='control'>
            <button className='button is-medium is-primary' role='button' type='submit' aria-label='submit'>
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>
        {renderSendingTime()}
        {renderSuccess()}
        {renderError()}
      </form>
    </div>
  )
}

export default injectIntl(ExpressContactForm)

const Info = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  background: #387cc9;
  color: #fff;
  padding: 10px;
`

const Success = styled.div`
  margin: 0 auto;
  width: 250px;
  text-align: center;
  background: hsl(141, 71%, 48%);
  color: #fff;
  padding: 10px;
`

const Error = styled.div`
  margin: 1rem auto;
  width: 250px;
  text-align: center;
  background: hsl(348, 100%, 61%);
  color: #fff;
  padding: 10px;
`

const Input = styled.input`
  height: 50px !important;
`
