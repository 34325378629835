import React from 'react'
import styled from 'styled-components'
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShippingFast } from '@fortawesome/free-solid-svg-icons/faShippingFast'
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons/faTabletAlt'
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import Fade from 'react-reveal/Fade'
// import Loadable from 'react-loadable'

// const Fade = Loadable({
//   loader: () => import('react-reveal/Fade'),
//   loading: () => <div>Loading...</div>
// })

const Features = ({ intl }) => {
  return (
    <section id='intro' className='hero'>
      <IOSWrap>
        <div className='hero-body'>
          <div className='columns is-multiline'>
            <Fade>
              <div className='column is-4'>
                <Feature>
                  <Link to='/products'>
                    <div className='has-text-centered'>
                      <FontAwesomeIcon icon={faTabletAlt} className='has-text-primary' size='5x' style={{ margin: '1.5rem' }} />
                      <h3 className='title has-text-white-ter'>
                        <FormattedMessage id='feature01Heading' />
                      </h3>
                      {/*<Text>
                        <FormattedMessage id='feature01Text' />
                      </Text>*/}
                    </div>
                  </Link>
                </Feature>
              </div>
            </Fade>
            {/*<div className='column is-1'>
              <MediaConnector>
                <FontAwesomeIcon icon='minus' className='has-text-primary' style={{ transform: 'rotate(90deg)' }} />
              </MediaConnector>
              <ScreenConnector>
                <FontAwesomeIcon icon='minus' className='has-text-primary' />
              </ScreenConnector>
            </div>*/}
            <Fade>
              <div className='column is-4'>
                <Feature>
                  <Link to='/products'>
                    <div className='has-text-centered'>
                      <FontAwesomeIcon icon={faClock} className='has-text-primary' size='5x' style={{ margin: '1.5rem' }} />
                      <h3 className='title has-text-white-ter'>
                        <FormattedMessage id='feature02Heading' />
                      </h3>
                      {/*<Text>
                        <FormattedMessage id='feature02Text' />
                      </Text>*/}
                    </div>
                  </Link>
                </Feature>
              </div>
            </Fade>

            <Fade>
              <div className='column is-4'>
                <Feature>
                  <Link to='/products'>
                    <div className='has-text-centered'>
                      <FontAwesomeIcon icon={faShippingFast} className='has-text-primary' size='5x' style={{ margin: '1.5rem' }} />
                      <h3 className='title has-text-white-ter'>
                        <FormattedMessage id='feature03Heading' />
                      </h3>
                      {/*<Text>
                        <FormattedMessage id='feature03Text' />
                      </Text>*/}
                    </div>
                  </Link>
                </Feature>
              </div>
            </Fade>
          </div>
        </div>
      </IOSWrap>
    </section>
  )
}

export default injectIntl(Features)

const IOSWrap = styled.div`
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  padding-bottom: env(safe-area-inset-bottom);
`

const Feature = styled.div`
  height: 250px;
  overflow: scroll;
  border-radius: 5%;
  background: rgba(0,0,0,0.5) none repeat scroll 0 0;
  boxShadow: 0 0 3px rgba(0, 0, 0, 0.15);
  padding: 30px 30px;
  transition: all 0.3s ease 0s;
  &:hover{
    cursor: pointer;
    box-shadow: 0px 40px 60px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 100;
    transform: translate(0, -5px);
    background: #2A2A2A;
  }
`

const Text = styled.p`
  margin: 0 auto;
  color: #fff;
  > span {
      hyphens: auto;
      text-align: justify;
    }
`

// const MediaConnector = styled.div`
//   text-align: center;
//   margin: auto auto;
//   display: none;
//   @media(min-width: 400px){
//     display: inline-block;
//   };
//   /* @media(min-width: 401px){
//     display: none;
//   }; */
// `
//
// const ScreenConnector = styled.div`
//   text-align: center;
//   margin: auto auto;
//   display: none;
//   /* @media(max-width: 400px){
//     display: none;
//   }; */
//   @media(max-width: 401px){
//     display: inline-block;
//   };
// `
