import React from 'react'
import styled from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from 'react-accessible-accordion'
import Img from 'gatsby-image'
import './faq.css'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-react-intl'

const Faq = ({ intl }) => (
  <StaticQuery
    query={graphql`
      query {
        qrCode: file(relativePath: { eq: "showcase-qr.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 60) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <section style={{ position: 'relative' }}>
        <div className='hero'>
          <div className='hero-body'>

            <div className='columns'>
              <div className='column is-12 has-text-centered'>
                <div style={{ position: 'relative' }}>
                  <h1 className='title is-2 has-text-white-ter'>FAQ</h1>
                  <div className='headline' />
                </div>
                <h2 className='subtitle'><FormattedMessage id='faqSubtitle' /></h2>
              </div>
            </div>
            <LazyLoad height={300} once>
              <div className='columns is-multiline'>
                <div className='column is-2' />
                <div className='column is-8'>
                  <Accordion allowZeroExpanded='true'>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <FormattedMessage id='question01' />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='has-text-white-ter'>
                        <p style={{ hyphens: 'auto', textAlign: 'justify', padding: '20px' }}>
                          <FormattedMessage id='answer01' />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <FormattedMessage id='question02' />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='has-text-white-ter'>
                        <p style={{ hyphens: 'auto', textAlign: 'justify', padding: '20px' }}>
                          <FormattedMessage id='answer02' />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <FormattedMessage id='question03' />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='has-text-white-ter'>
                        <p style={{ hyphens: 'auto', textAlign: 'justify', padding: '20px' }}>
                          <FormattedMessage id='answer03' />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <FormattedMessage id='question04' />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='has-text-white-ter'>
                        <div className='columns is-vcentered is-centered'>
                          <div className='column is-6'>
                            <p style={{ hyphens: 'auto', padding: '20px' }}>
                              <FormattedMessage id='answer04' />
                            </p>
                          </div>

                          <div className='column is-6' style={{ padding: '10px' }}>
                            <ImgWrapper>
                              <Img fixed={data.qrCode.childImageSharp.fixed} style={{ margin: '0 auto', width: '60px', height: '80px' }} objectFit='cover'
                              objectPosition='50% 50%' className='image' alt='qrCode' />
                            </ImgWrapper>
                          </div>


                        </div>
                      </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <FormattedMessage id='question05' />
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='has-text-white-ter'>
                        <p style={{ hyphens: 'auto', textAlign: 'justify', padding: '20px' }}>
                          <FormattedMessage id='answer05' />
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
                <div className='column is-2' />
              </div>
            </LazyLoad>
          </div>
        </div>
      </section>
    )}
  />
)

export default injectIntl(Faq)

const ImgWrapper = styled.div`
  margin: 0 auto;
  width: 80px;
  height: 120px;
`
