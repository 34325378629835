import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
// import Loadable from 'react-loadable'
// import Intro from '../components/Intro'
import Benefits from '../components/Benefits'
import LanguageSwitcher from '../components/LanguageSwitcher'
import Features from '../components/Features'
import Faq from '../components/Faq'
// import Layout from '../components/Layout'
import Layout from '../layouts'
import GetStarted from '../components/GetStarted'
import ScrollDownAnimation from '../components/ScrollDownAnimation'
// import Booking from '../components/Booking'
// import ReservationWrapper from '../components/ReservationWrapper'
// import LazyLoad from 'react-lazyload'
import TextAnimation from 'react-animate-text'

import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'

// const LazyLoad = Loadable({
//   loader: () => import('react-lazyload'),
//   loading: () => <div>Loading...</div>
// })

// const Layout = Loadable({
//   loader: () => import('../layouts'),
//   loading: () => <div>Loading...</div>
// })

// const ScrollDownAnimation = Loadable({
//   loader: () => import('../components/ScrollDownAnimation'),
//   loading: () => <div>Loading...</div>
// })

{/* const Booking = Loadable({
  loader: () => import('../components/Booking'),
  loading: () => <div>Loading...</div>
}) */}

// const Fade = Loadable({
//   loader: () => import('react-reveal/Fade'),
//   loading: () => <div>Loading...</div>
// })
// import IntroBestseller from '../components/IntroBestseller'
// import IntroSale from '../components/IntroSale'
// import Contact from '../components/contact/index'
// import CustomLink from '../components/CustomLink'
// import styled from 'styled-components'
// import FeaturedProducts from '~/components/ProductGridNewest'
// import ProductCategories from '~/components/ProductCategories'
// import AllProducts from '~/components/ProductGrid'


// const TextAnimation = Loadable({
//   loader: () => import('react-animate-text'),
//   loading: () => <div>Loading...</div>
// })

// const IOSWrap = styled.div`
//   padding-top: env(safe-area-inset-top);
//   padding-left: env(safe-area-inset-left);
//   padding-right: env(safe-area-inset-right);
//   padding-bottom: env(safe-area-inset-bottom);
// `

export const HomePageTemplate = ({
  home,
}) => {
  const image = getImage(home.image)

  const intl = useIntl()

  const headerText = intl.formatMessage({ id: 'headerText' })

  return (
    <>

      <div className='imageContainer' style={{ position: 'relative', height: '100vh', background: '#2A2A2A' }}>
        {/*<Img
          fluid={home.image.childImageSharp.fluid}
          style={{
            width: '100%',
            height: '100vh',
            position: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            filter: 'brightness(70%)'
          }}
        />*/}
        <GatsbyImage
          image={image}
          alt='Background-Image'
          style={{
            width: '95%',
            height: '90vh',
            position: 'fixed',
            left: '0',
            right: '0',
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            filter: 'brightness(70%)',
            // backgroundSize: 'cover',
            margin: '4rem auto',
            //backgroundColor: '#2A2A2A',
            textAlign: 'center',
            display: 'inline-block',
            borderRadius: '2%'
          }}
        />
      </div>

      <div style={{ position: 'absolute', top: '0', right: '0', zIndex: '3000' }}>
        <LanguageSwitcher />
      </div>
      <section className='hero is-medium' style={{ position: 'absolute', top: '10%', marginLeft: 'auto', marginRight: 'auto', right: '0', left: '0px', width: '100%' }}>

        <div className='hero-body' style={{ paddingTop: '2rem', paddingBottom: '0px' }}>

            <div className='columns is-multiline is-centered'>
              <div className='column is-12 has-text-centered'>
                <h1 className='title is-2 is-size-3-mobile has-text-white-ter is-uppercase text-shadow'>
                  Click & Collect
                </h1>
              </div>

              <div className='column is-12' style={{ margin: '2rem auto' }}>
                <div style={{ maxWidth: '200px', height: 'auto', margin: '0 auto', display: 'block' }}>
                  <StaticImage
                    src='../img/click-collect.png'
                    alt='Logo'
                    placeholder='blurred'
                    layout='constrained'
                    width={200}
                    loading='eager'
                  />
                </div>
              </div>
              <div className='column is-12 has-text-centered'>
                <TextAnimation>
                  <span className='title is-3 has-text-white-ter has-text-centered text-shadow'>
                  {/*{home.mainpitch.subtitle}*/}
                    {headerText}
                  </span>
                </TextAnimation>
              </div>

              <div className='column is-12 has-text-centered' style={{ position: 'relative' }}>
                <ScrollDownAnimation />
              </div>
            </div>

        </div>
      </section>

      {/*<LazyLoad height={200} once>*/}

        {/*<Intro />*/}
        <Features />

        <Benefits />
        {/* <Booking /> */}

        {/*
          <FeaturedProducts />
        <ProductCategories />
        <AllProducts />
        */}

        {/*<BlogRoll />*/}
        {/* <div className='column is-12 has-text-centered' style={{ background: '#fff', position: 'relative', paddingBottom: '4rem' }}>
          <Link className='button is-medium is-outlined is-primary' to='/blog'>
            alle Stories
          </Link>
        </div> */}
        <GetStarted />

        <Faq />

      {/*</LazyLoad>*/}
    </>
  )
}

class HomePage extends React.Component {
  render () {
    const { data } = this.props
    const {
      data: { navbarData, footerData, bannerData, browserTab }
    } = this.props
    const { frontmatter: home } = data.homePageData.edges[0].node

    return (
      <Layout navbarData={navbarData} footerData={footerData} bannerData={bannerData} browserTab={browserTab}>
        <HomePageTemplate home={home} />
      </Layout>
    )
  }
}

HomePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
}

export default HomePage

export const pageQuery = graphql`
  query HomePageQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 2048
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
            mainpitch {
              title
              subtitle
            }
          }
        }
      }
    }
    ...LayoutFragment
    homePageData: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "home-page" } } }) {
      edges {
        node {
          frontmatter {
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 2048
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
            mainpitch {
              title
              subtitle
            }
          }
        }
      }
    }
  }
`

// const Gallery = styled.div`
//   display: grid;
//   grid-template-columns: 1fr 1fr;
//   @media (min-width: 700px) {
//     grid-template-columns: 1fr 1fr;
//   }
//   @media (min-width: 900px) {
//     grid-template-columns: 1fr 1fr 1fr;
//   }
//   @media (min-width: 1100px) {
//     grid-template-columns: repeat(6, 1fr);
//   }
//   grid-gap: 15px;
//   .gatsby-image-outer-wrapper {
//     height: 100%;
//   }
// `
